@tailwind components;
@tailwind theme;


@layer components {
  .accordion-menu {
    position: absolute;
    width: 14rem;
    --ld-accordion-bg-col: theme('colors.rich-purple');;
    --ld-accordion-toggle-col: theme("colors.white");
  }

  .accordion-menu-toggle {
    --ld-accordion-bg-col: theme('colors.rich-purple-light');
    --ld-accordion-bg-col-active: theme('colors.rich-purple-light');
    --ld-accordion-toggle-col: theme('colors.white');
    --ld-accordion-trigger-icon-col: theme('colors.white');
    --ld-accordion-col-hover:theme('colors.white');
    --ld-accordion-col-active: theme('colors.white');
    height: 4rem;
  }


  .accordion-menu-panel {
    transform: translate(14rem, -4rem);
    z-index: 1;
    display: none;
  }
  .accordion-menu-panel.open {
    display: block;
  }
}

