@import '../assets/styles/globals';
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

.ld-button {
  background-color: $vibrant-magenta;

  &:hover,
  &:active {
    background-color: $vibrant-magenta-lighter;
  }
}

.ld-modal::part(dialog) {
  --ld-modal-max-inline-size: 50rem;
}