@import 'assets/styles/globals';
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .badge ld-loading {
    --ld-loading-size: 1rem !important;
  }
  ld-loading.ld-loading-black {
    --ld-loading-col: #000 !important;
  }
  ld-loading.ld-loading-white {
    --ld-loading-col: #fff !important;
  }
}
