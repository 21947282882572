.sidebar {
  width: 15.9rem;
}

.sidebar-content {
  width: 14rem;
}

@media screen and (max-width: 540px) {
  .sidebar-content {
    width: 5rem;
  }
}
