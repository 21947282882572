@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  ld-select::part(root) {
    @apply w-max;
    --ld-select-min-width: 5rem;
    --ld-select-padding-right-sm: 0.5rem;
  }
  .ld-loading-sm {
    --ld-loading-size: 1rem !important;
  }
}
