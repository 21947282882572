@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  html,
  body {
    height: 100%;
    line-height: 1.4;
    font-weight: 400;
    font-family: Lato, sans-serif;
  }

  main {
    display: block;
  }

  strong {
    font-weight: 900;
  }

  .overflow-anchor-none {
    overflow-anchor: none;
  }
  .ld-badge--warning {
    --ld-badge-bg-col: var(--ld-thm-warning);
    color: #000;
  }
  .ld-badge--danger {
    --ld-badge-bg-col: var(--ld-thm-error);
    color: #fff;
  }
  .ld-badge--pending {
    --ld-badge-bg-col: var(--ld-col-sb);
    color: var(--ld-col-neutral-900);
  }
  .ld-badge--accepted {
    --ld-badge-bg-col: var(--ld-col-sg);
    color: var(--ld-col-neutral-900);
  }
  .ld-badge--rejected {
    --ld-badge-bg-col: var(--ld-col-sp);
    color: var(--ld-col-neutral-900);
  }
  .ld-badge--expired {
    --ld-badge-bg-col: var(--ld-col-sy);
    color: var(--ld-col-neutral-900);
  }
}

@layer components {
  .tooltip {
    @apply card card-active break-words whitespace-normal max-w-[300px];
  }
  .sort-button {
    @apply h-4 text-rich-purple rounded-s hover:text-rich-purple-darker;
  }
  .sort-button:hover {
    background-color: rgb(250, 228, 246)!important;
  }
  .sort-button.active {
    color: var(--ld-col-vc-400)
  }

  .markdown {
    @apply text-black leading-normal break-words text-sm overflow-hidden flex-1;
  }

  .markdown--error {
    @apply text-thm-error;
  }

  .markdown > :not(:last-child) {
    @apply mt-0 mb-ld-16;
  }

  .markdown li + li {
    @apply mt-ld-4;
  }

  .markdown li > p + p {
    @apply mt-ld-24;
  }

  .markdown strong {
    @apply font-semibold;
  }

  .markdown a:hover {
    @apply underline
  }

  .markdown a {
    @apply text-rich-purple font-semibold;
  }

  .markdown strong a {
    @apply font-bold;
  }

  .markdown h1 {
    @apply leading-tight border-b text-4xl font-semibold mb-ld-16 mt-ld-24 pb-ld-8;
  }

  .markdown h2 {
    @apply leading-tight border-b text-3xl font-semibold mb-ld-16 mt-ld-24 pb-ld-8;
  }

  .markdown h3 {
    @apply leading-snug text-2xl font-semibold mb-ld-16 mt-ld-24;
  }

  .markdown h4 {
    @apply leading-none text-xl font-semibold mb-ld-16 mt-ld-24;
  }

  .markdown h5 {
    @apply leading-tight text-base font-semibold mb-ld-16 mt-ld-24;
  }

  .markdown h6 {
    @apply leading-tight text-base font-semibold text-black mb-ld-16 mt-ld-24;
  }

  .markdown blockquote {
    @apply typo-body-s border-l-4 border-rich-black-light px-ld-16 text-black;
  }

  .markdown code {
    @apply typo-body-s inline bg-sensitive-grey-darker rounded-m px-ld-4 py-ld-2;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  }

  .markdown pre {
    @apply bg-sensitive-grey-darker rounded-m p-ld-16 overflow-scroll;
  }

  .markdown pre code {
    @apply inline-block bg-transparent p-0 overflow-visible rounded-none;
  }

  .markdown ul {
    @apply typo-body-s pl-ld-32 list-disc;
  }

  .markdown ol {
    @apply typo-body-s pl-ld-32 list-decimal;
  }

  .markdown kbd {
    @apply typo-body-s inline-block rounded-m border px-ld-4 py-ld-2 align-middle font-normal shadow-rich-black-lighter;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  }

  .markdown table {
    @apply typo-body-s rounded-l border-2 border-solid overflow-hidden bg-black;
  }

  .markdown table th {
    @apply bg-sensitive-grey border-x-0 border-black text-left;
    /*@apply bg-sensitive-grey border-x-0 border-1 border-1 border-black text-left;*/
  }

  .markdown table td {
    @apply bg-white border-x-0 border-l-social-teams;
    /*@apply bg-wht border-x-0 border-1 border-1 border-black;*/
  }

  .markdown th {
    @apply border py-ld-4 px-ld-12;
  }

  .markdown td {
    @apply border py-ld-4 px-ld-12;
  }

  /* Override pygments style background color. */
  .markdown .highlight pre {
    /*background-color: var(--ld-col-rich-black-lighter) !important;*/
    @apply bg-black !important;
  }
}

.LD__TooltipWrapper {
  min-width: initial !important;
}

.LD__ModalContent.editor-modal > div:last-of-type {
  padding: 0 !important;
}

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}
 /*
 -neutral-100 -> -rich-black-lighter
 -neutral-010 -> -sensitive-grey
 -neutral-200 -> -rich-black-lighter

 -neutral-300 -> -grey
 -neutral-600 -> -rich-black
 -neutral-900 -> -black
 */
